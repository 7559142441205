import * as React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import PageCopy from "../components/PageCopy";

const CuratorContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BioImage = styled.div`
  text-align: center;
  padding: 2rem;
  * {
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
`;

export default function Curator({ data }) {
  const pageCopy = data.pageCopy.curatorBio.split("\n");
  console.log(pageCopy);
  return (
    <CuratorContent>
      <BioImage>
        <StaticImage
          src="../images/the_curator.jpg"
          width={500}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="The curator"
        />
      </BioImage>
      <PageCopy pageCopy={pageCopy} />
    </CuratorContent>
  );
}

export const query = graphql`
  query {
    pageCopy: sanityWebsiteCopy {
      curatorBio
    }
  }
`;
